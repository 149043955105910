import React from "react"
import PropTypes from "prop-types"
import Fade from 'react-reveal/Fade'

const BraceletColumn = ({ title, price,price_mx,dateRange, paragraphs, id}) => (
    <div className="bracelet-column" id={id}>
        <Fade duration={2000}>
            <h1 className="bracelet-column__header">{title}</h1>
            <h2 className="bracelet-column__price">{price}</h2>
            <h2 className="bracelet-column__price-mx">{price_mx}</h2>
            <h3 className="bracelet-column__date-range">{dateRange}</h3>
        </Fade>
        <Fade duration={2000}>
            {paragraphs.map((paragraph, index) => (
                <p className="bracelet-column__paragraph" key={index}>
                    {paragraph}
                </p>
            ))}
        </Fade>
    </div>
)

BraceletColumn.propTypes = {
    title: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    dateRange: PropTypes.string.isRequired,
    paragraphs: PropTypes.array.isRequired,
    id: PropTypes.string,
}

export default BraceletColumn