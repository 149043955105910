import React from 'react'
import Fade from 'react-reveal/Fade'
import Slide from 'react-reveal/Slide'

import Layout from '../components/layout'
import SEO from '../components/seo'
import HostingRoom from '../components/hostingRoom'
import TicketForm from '../components/ticketForm'
import ContactSection from '../components/contactSection'

import ImageGroup from '../images/group-201.png'
import ImageGroup2 from '../images/group-201@2x.png'
import ImageGroup3 from '../images/group-201@3x.png'

import ImageGroupMobile from '../images/group-206.png'
import ImageGroupMobile2 from '../images/group-206@2x.png'
import ImageGroupMobile3 from '../images/group-206@3x.png'

import ImageIntersection3 from '../images/intersection-3.svg'
import ImageInterSection11 from '../images/intersection-11.svg'

import { HOSTING_ROOMS } from '../constants'

const Hosting = () => (
  <Layout
    gradient={{
      background: 'linear-gradient(to bottom, #95785e, rgba(149, 120, 94, 0))',
    }}
  >
    <SEO title="Hospedaje" />
    <div className="hosting__main-image">
      <Fade duration={2000}>
        <img
          src={ImageGroup}
          srcSet={`${ImageGroup2} 2x,
                    ${ImageGroup3} 3x`}
          className="main-image__cover"
          alt="cover"
        />
      </Fade>
      <img
        src={ImageGroupMobile}
        srcSet={`${ImageGroupMobile2} 2x,
                    ${ImageGroupMobile3} 3x`}
        className="main-image__cover main-image__cover--mobile"
        alt="cover"
      />
    </div>
    <img
      src={ImageIntersection3}
      alt="decorator"
      className="hosting__image-decorator"
    />

    <section className="hosting__rooms">
      {HOSTING_ROOMS.map((room, index) => (
        <HostingRoom {...room} left={index % 2 !== 0} key={index} />
      ))}
    </section>
    <img
      src={ImageInterSection11}
      alt="decorator 2"
      className="hosting__image-decorator-2"
    />
    <section className="hosting__ticket-form">
      <TicketForm />
    </section>
    <Fade bottom duration={2000}>
      <ContactSection id="contact" type="tickets" />
    </Fade>
  </Layout>
)

export default Hosting
