import React from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"

import Button from "../components/button"

import ImagePhone from "../images/path-509.svg"
import ImageEmail from "../images/group-108.svg"

const ContactSection = ({id, type}) => {
    const goToTickets = () => {
        navigate('/boletos')
    }
    const goToHosting = () => {
        navigate('/hospedaje')
    }
    return (
        <section className="contact-section" id={id}>
            {type === 'tickets' ? (
                <Button onClick={goToTickets}>COMPRAR BOLETOS</Button>
            ) : (
                <Button onClick={goToHosting}>COMPRAR HOSPEDAJE</Button>

            )}
            <h3 className="contact__header">Contacto</h3>
            <p className="contact__text">
                Si tienes dudas sobre el evento o el proceso de compra,
                puedes contactar a nuestro concierge.
                </p>
            <p className="contact__text">Horario de atención 11:00 a 20:00</p>
            <div className="contact__row">
                <a className="contact__button" href="mailto:invitados@artecareyes.com"><img src={ImageEmail} alt="email" className="iconContact"/></a>
                <a className="contact__button" href="tel:5548904469"><img src={ImagePhone} alt="phone" className="iconContact"/></a>
            </div>
        </section>
    )
}

ContactSection.propTypes = {
    id: PropTypes.string,
}
export default ContactSection