import React from "react"
import PropTypes from "prop-types"

const NumberInput = ({value, onChange}) => {

    const buttonClick = (e, value) => {
        e.preventDefault()
        if (value >= 0) {
            onChange(value)
        }
    }

    return (
        <div className="number-input">
            <button
                onClick={(e) => buttonClick(e, value - 1)}
                className="number-input__button number-input__button--left"
            >
                -
            </button>
            <p className="number-input__value">{value}</p>
            <button
                onClick={(e) => buttonClick(e, value + 1)}
                className="number-input__button number-input__button--right"
            >
                +
            </button>
        </div>
    )
}

NumberInput.propTypes = {
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
}

export default NumberInput