import React from "react"
import PropTypes from "prop-types"

const Input = ({label, type, value, onChange, isRequired = false, inputClass, errorMessage }) => (
    <div className="input-wrapper">
        <label className="input-label">{label}</label>
        <input
            type={type || 'text'}
            className={"input " + inputClass}
            required={isRequired}
            onChange={({target}) => onChange(target.value)}
        />
        <label className="input-label-error">{errorMessage}</label>
    </div>
)

Input.propTypes = {
    label: PropTypes.string.isRequired,
    type: PropTypes.string,
    isRequired: PropTypes.bool,
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
}

export default Input