import React, { useEffect } from "react"
import Modal from 'react-modal'
import PropTypes from "prop-types"

import ImageSuccess from "../images/group-110.svg"
import ImageClose from "../images/close_36px.svg"

const FormSubmitModal = ({isOpen, close}) => {
    useEffect(() => {
        Modal.setAppElement('body');
    }, []);

    return (
        <Modal
            isOpen={isOpen}
            overlayClassName="modal-overlay"
            className="modal"
        >
            <button className="modal__close-button" onClick={close}>
                <img src={ImageClose} alt="close" />
            </button>
            <img src={ImageSuccess} alt="success" />
            <p className="modal__title">¡GRACIAS!</p>
            <p className="modal__message">
                En menos de 48 hrs. nuestro concierge se pondrá 
                en contacto contigo para completar la compra.
            </p>
        </Modal>
    )
}

FormSubmitModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
}

export default FormSubmitModal