import React, { useState } from "react"
import PropTypes from "prop-types"

import Input from "./input"
import RadioInput from "./radioInput"
import NumberInput from "./numberInput"
import Button from "./button"
import BraceletColumn from "./braceletColumn"
import FormSubmitModal from "./formSubmitModal"
import FormWrongSubmitModal from "./formWrongSubmitModal"
import Fade from 'react-reveal/Fade'
import axios from 'axios';

const BRACELET_TYPES = {
    'PULSERA': 'PULSERA',
    // 'VIP': 'VIP',
}

const HOSTING_TYPES = {
    'FLORES': 'FLORES',
    'CAREYES_CLUB': 'CAREYES_CLUB',
    'VILLA': 'VILLA',
}

const EMPTY_FORM = {
    name: '',
    phone: '',
    email: '',
    email2: '',
    braceletType: true,
    braceletVIPType: false,
    hostingCasita: true,
    hostingNido: false,
    hostingClub: false,
}

const EMPTY_ERRORS = {
    name: '',
    phone: '',
    email: '',
    email2: '',
}
const WRONG_RESPONSE = {
    type: 'error',
    status: '500',
    message: '',
}

const TicketForm = ({isBraceletForm}) => {
    const [ isModalOpen, setIsModalOpen ] = useState(false);
    const [form, setForm ] = useState(EMPTY_FORM)
    const [formError, setFormError ] = useState(EMPTY_ERRORS)
    const [braceletItems, setBraceletItems] = useState({
        [BRACELET_TYPES.PULSERA]: 1,
        // [BRACELET_TYPES.VIP] : 0,
    })
    const [axiosResponse, setAxiosResponse] = useState({WRONG_RESPONSE})
    const [roomItems, setRoomItems] = useState({
        [HOSTING_TYPES.FLORES]: 1,
        [HOSTING_TYPES.CAREYES_CLUB] : 0,
        [HOSTING_TYPES.VILLA] : 0,
    })

    const onChangeForm = (item) => {
        const regexp = new RegExp(`^-?[0-9]*$`);
        setForm({
            ...form,
            ...item,
        })
        if (isBraceletForm) {
            if( item.hasOwnProperty('braceletType') ){
                if( item.braceletType && braceletItems.PULSERA === 0){
                    onChangeBraceletItems({PULSERA: 1})
                }
                if(!item.braceletType){
                    onChangeBraceletItems({PULSERA: 0})
                }
            }
            if( item.hasOwnProperty('braceletVIPType') ){
                if( item.braceletVIPType && braceletItems.VIP === 0){
                    onChangeBraceletItems({VIP: 1})
                }
                if(!item.braceletVIPType){
                    onChangeBraceletItems({VIP: 0})
                }
            }
        }else{
            if( item.hasOwnProperty('hostingCasita') ){
                if( item.hostingCasita && roomItems.CASITA === 0){
                    onChangeRoomItems({CASITA: 1})
                }
                if(!item.hostingCasita){
                    onChangeRoomItems({CASITA: 0})
                }
            }
            if( item.hasOwnProperty('hostingNido') ){
                if( item.hostingNido && roomItems.NIDO === 0){
                    onChangeRoomItems({NIDO: 1})
                }
                if(!item.hostingNido){
                    onChangeRoomItems({NIDO: 0})
                }
            }
            if( item.hasOwnProperty('hostingClub') ){
                if( item.hostingClub && roomItems.CLUB === 0){
                    onChangeRoomItems({CLUB: 1})
                }
                if(!item.hostingClub){
                    onChangeRoomItems({CLUB: 0})
                }
            }
        }
        if(item.hasOwnProperty('name')){
            if(item.name.length < 1){
                setFormError({
                    ...formError,
                    ...{name: 'Este campo es requerido.'},
                })
            }else{
                setFormError({
                    ...formError,
                    ...{name: ''},
                })
            }
        }
        if(item.hasOwnProperty('phone')){
            if(!regexp.test(item.phone)){
                setFormError({
                    ...formError,
                    ...{phone: 'Ingresa un número telefónico valido.'},
                })
            }else{
                if(item.phone.length < 8 || item.phone.length > 12){
                    setFormError({
                        ...formError,
                        ...{phone: 'Ingresa un número telefónico valido.'},
                    })
                }else{
                    setFormError({
                        ...formError,
                        ...{phone: ''},
                    })
                }
            }
        }
        if(item.hasOwnProperty('email')){
            if(item.email.length < 1){
                setFormError({
                    ...formError,
                    ...{email: 'Este campo es requerido'},
                })
            }else{
                if(!item.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)){
                    setFormError({
                        ...formError,
                        ...{email: 'Este campo debe ser un correo electrónico válido.'},
                    })
                }else{
                    setFormError({
                        ...formError,
                        ...{email: ''},
                    })
                }
            }
        }
        if(item.hasOwnProperty('email2')){
            if(item.email2.length < 1){
                setFormError({
                    ...formError,
                    ...{email2: 'Este campo es requerido'},
                })
            }else{
                if(!item.email2.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)){
                    setFormError({
                        ...formError,
                        ...{email2: 'Este campo debe ser un correo electrónico válido.'},
                    })
                }else{
                    if(item.email2 !== form.email){
                        setFormError({
                            ...formError,
                            ...{email2: 'Verifica tu confirmación de correo electrónico.'},
                        })
                    }else{
                        setFormError({
                            ...formError,
                            ...{email2: ''},
                        })
                    }
                }
            }
        }
    }
    const onChangeBraceletItems = (item) => {
        setBraceletItems({
            ...braceletItems,
            ...item,
        })
        if (isBraceletForm) {
            if( item.hasOwnProperty('PULSERA') ){
                if( item.PULSERA === 0){
                    setForm({
                        ...form,
                        ...{braceletType: false},
                    })
                }
                if( item.PULSERA > 0 && !form.braceletType){
                    setForm({
                        ...form,
                        ...{braceletType: true},
                    })
                }
            }
            if( item.hasOwnProperty('VIP') ){
                if( item.VIP === 0){
                    setForm({
                        ...form,
                        ...{braceletVIPType: false},
                    })
                }
                if( item.VIP > 0 && !form.braceletVIPType){
                    setForm({
                        ...form,
                        ...{braceletVIPType: true},
                    })
                }
            }
        }
    }

    const onChangeRoomItems = (item) => {
        setRoomItems({
            ...roomItems,
            ...item,
        })
        if (!isBraceletForm) {
            if( item.hasOwnProperty('CASITA') ){
                if( item.FLORES === 0){
                    setForm({
                        ...form,
                        ...{hostingCasita: false},
                    })
                }
                if( item.FLORES > 0 && !form.hostingCasita){
                    setForm({
                        ...form,
                        ...{hostingCasita: true},
                    })
                }
            }
            if( item.hasOwnProperty('NIDO') ){
                if( item.CAREYES_CLUB === 0){
                    setForm({
                        ...form,
                        ...{hostingNido: false},
                    })
                }
                if( item.CAREYES_CLUB > 0 && !form.hostingNido){
                    setForm({
                        ...form,
                        ...{hostingNido: true},
                    })
                }
            }
            if( item.hasOwnProperty('CLUB') ){
                if( item.VILLA === 0){
                    setForm({
                        ...form,
                        ...{hostingClub: false},
                    })
                }
                if( item.VILLA > 0 && !form.hostingClub){
                    setForm({
                        ...form,
                        ...{hostingClub: true},
                    })
                }
            }
        }
    }

    const isFormValid = () => {
        const { name, phone, email, email2} = form
        let areInputsValid = false
        let areBraceletValid = false
        let areRoomsValid = false

        if (name !== '' && phone !== '' && email !== '' && email2 !== ''
            && email === email2) {
                if(formError.name.length === 0 && formError.phone.length === 0 &&
                    formError.email.length === 0 && formError.email2.length === 0)
                    areInputsValid = true;
        }

        if (isBraceletForm) {
            if ((form.braceletType && braceletItems.PULSERA > 0)
                || (form.braceletVIPType && braceletItems.VIP > 0)) {
                areBraceletValid = true
            }
            return areInputsValid && areBraceletValid

        } else {
            if ((form.hostingCasita && roomItems.CASITA > 0)
                || (form.hostingNido && roomItems.NIDO > 0)
                    || (form.hostingClub && roomItems.CLUB > 0)) {
                areRoomsValid = true
            }
            return areInputsValid && areRoomsValid
        }
    }

    const submitForm = (e) => {
        e.preventDefault()
        if (isBraceletForm) {
            axios.post('https://hooks.zapier.com/hooks/catch/2364137/ods4jzh/', {form: form, braceletItems: braceletItems},{headers:{
                'Content-Type': 'application/x-www-form-urlencoded',
            }})
                .then(
                    (response) => {
                        setAxiosResponse({
                            type: 'success',
                            status: '200',
                            message: 'El mensaje fue enviado con éxito.',
                        })
                        console.log(response, 'response')
                        setIsModalOpen(true)
                    },
                    (error) => {
                        setAxiosResponse({
                            type: 'error',
                            status: '500',
                            message: 'Server error.',
                        })
                        console.log(error, 'error')
                        setIsModalOpen(true)
                    },
                ).catch(
                    (error) => {
                        setAxiosResponse({
                            type: 'error',
                            status: '500',
                            message: 'Server error.',
                        })
                        console.log(error, 'error')
                        setIsModalOpen(true)
                    },
                );
        }else{
            axios.post('https://hooks.zapier.com/hooks/catch/2364137/odstbvs/', {form: form, roomItems: roomItems},{headers:{
                'Content-Type': 'application/x-www-form-urlencoded',
            }})
            .then(
                (response) => {
                    setAxiosResponse({
                        type: 'success',
                        status: '200',
                        message: 'El mensaje fue enviado con éxito.',
                    })
                    console.log(response, 'response')
                    setIsModalOpen(true)
                },
                (error) => {
                    setAxiosResponse({
                        type: 'error',
                        status: '500',
                        message: 'Server error.',
                    })
                    console.log(error, 'error')
                    setIsModalOpen(true)
                },
            ).catch(
                (error) => {
                    setAxiosResponse({
                        type: 'error',
                        status: '500',
                        message: 'Server error.',
                    })
                    console.log(error, 'error')
                    setIsModalOpen(true)
                },
            );
        }
    }

    const closeModal = () => {
        setForm(EMPTY_FORM)
        setIsModalOpen(false)
    }

    return (
        <React.Fragment>
            <Fade duration={3000} cascade>
            <form className="ticket-form">
                <p className="ticket-form__text">
                    DÉJANOS TUS DATOS Y TE ESTAREMOS CONTACTANDO PARA REALIZAR
                    TU COMPRA.
                </p>
                    <div className="ticket-form__row">
                        <Input
                            label='NOMBRE*'
                            isRequired={true}
                            value={form.name}
                            onChange={(value) => onChangeForm({ name: value})}
                            inputClass={formError.name.length > 0 ? 'error': ''}
                            errorMessage={formError.name.length > 0 && formError.name}
                        />
                        <Input
                            label='TELEFONO*'
                            isRequired={true}
                            value={form.phone}
                            onChange={(value) => onChangeForm({ phone: value})}
                            inputClass={formError.phone.length > 0 ? 'error': ''}
                            errorMessage={formError.phone.length > 0 && formError.phone}
                        />
                    </div>
                    <div className="ticket-form__row">
                        <Input
                            label='CORREO ELECTRONICO*'
                            type='email'
                            isRequired={true}
                            value={form.email}
                            onChange={(value) => onChangeForm({ email: value})}
                            inputClass={formError.email.length > 0 ? 'error': ''}
                            errorMessage={formError.email.length > 0 && formError.email}
                        />
                        <Input
                            label='CONFIRMA TU CORREO*'
                            type='email'
                            isRequired={true}
                            value={form.email2}
                            onChange={(value) => onChangeForm({ email2: value})}
                            inputClass={formError.email2.length > 0 ? 'error': ''}
                            errorMessage={formError.email2.length > 0 && formError.email2}
                        />
                    </div>
                    {isBraceletForm ? (
                        <React.Fragment>
                            <Fade duration={2000} delay={300}>
                                <div className="ticket-form__row">
                                    <p className="ticket-form__label">TIPO DE BOLETO</p>
                                    <p className="ticket-form__label ticket-form__row--desktop">CANTIDAD</p>
                                </div>
                            </Fade>
                            <p className="ticket-form__label ticket-form__row huespedes">CANTIDAD</p>
                            <Fade duration={2000} delay={300}>
                                <div className="ticket-form__row">
                                    <RadioInput
                                        label='PULSERA'
                                        checked={form.braceletType === true}
                                        name="bracelet"
                                        value={BRACELET_TYPES.PULSERA}
                                        onChange={() => onChangeForm({ braceletType: !form.braceletType})}
                                        type="checkbox"
                                    />
                                    <div>
                                    <NumberInput
                                        value={braceletItems.PULSERA}
                                        onChange={(value) => onChangeBraceletItems({[BRACELET_TYPES.PULSERA]:value})}
                                    />
                                    </div>
                                </div>
                            </Fade>
                            {/* <Fade duration={2000} delay={300}>
                                <div className="ticket-form__row ticket-form__row--desktop">
                                    <div></div>
                                    <p className="ticket-form__label">CANTIDAD</p>
                                </div>
                            </Fade>
                            <div className="ticket-form__row">
                                <RadioInput
                                    label='PULSERA VIP'
                                    checked={form.braceletVIPType === true}
                                    name="vip"
                                    value={BRACELET_TYPES.VIP}
                                    onChange={() => onChangeForm({ braceletVIPType: !form.braceletVIPType })}
                                    type="checkbox"
                                />
                                <div>
                                    <NumberInput
                                        value={braceletItems.VIP}
                                        onChange={(value) => onChangeBraceletItems({ [BRACELET_TYPES.VIP]: value})}
                                    />
                                </div>
                            </div> */}
                        </React.Fragment>
                    ) : (
                            <React.Fragment>
                            <Fade duration={2000} delay={300}>
                                <div className="ticket-form__row">
                                    <p className="ticket-form__label">TIPO DE HOSPEDAJE</p>
                                    <p className="ticket-form__label ticket-form__row--desktop">HUÉSPEDES</p>
                                </div>
                                <div className="ticket-form__row">
                                    <RadioInput
                                        label='DE LAS FLORES'
                                        checked={form.hostingCasita === true}
                                        name="room"
                                        value={HOSTING_TYPES.FLORES}
                                        onChange={() => onChangeForm({hostingCasita: !form.hostingCasita})}
                                        type="checkbox"
                                    />
                                     <p className="ticket-form__label ticket-form__row huespedes">HUÉSPEDES</p>
                                    <div>
                                        <NumberInput
                                            value={roomItems.FLORES}
                                            onChange={(value) => onChangeRoomItems({ [HOSTING_TYPES.CASITA]: value})}
                                        />
                                    </div>
                                </div>
                                <div className="ticket-form__row ticket-form__row--desktop">
                                    <div></div>
                                    {/* <p className="ticket-form__label">HUÉSPEDES</p> */}
                                </div>
                                <div className="ticket-form__row">
                                    <RadioInput
                                        label='CAREYES CLUB'
                                        checked={form.hostingNido === true}
                                        name="room"
                                        value={HOSTING_TYPES.CAREYES_CLUB}
                                        onChange={() => onChangeForm({hostingNido: !form.hostingNido})}
                                        type="checkbox"
                                    />
                                    <div>
                                        <NumberInput
                                            value={roomItems.CAREYES_CLUB}
                                            onChange={(value) => onChangeRoomItems({ [HOSTING_TYPES.NIDO]: value})}
                                        />
                                    </div>
                                </div>
                                <div className="ticket-form__row ticket-form__row--desktop">
                                    <div></div>
                                    {/* <p className="ticket-form__label">HUÉSPEDES</p> */}
                                </div>
                                <div className="ticket-form__row">
                                    <RadioInput
                                        label='PRIVATE VILLAS'
                                        checked={form.hostingClub === true}
                                        name="room"
                                        value={HOSTING_TYPES.VILLA}
                                        onChange={() => onChangeForm({hostingClub: !form.hostingClub})}
                                        type="checkbox"
                                    />
                                    <div>
                                        <NumberInput
                                            value={roomItems.VILLA}
                                            onChange={(value) => onChangeRoomItems({ [HOSTING_TYPES.CLUB]: value})}
                                        />
                                    </div>
                                </div>
                                </Fade>
                            </React.Fragment>
                        )}

                    <Button
                        type='secondary'
                        onClick={e => submitForm(e)}
                        disabled={!isFormValid()}
                        buttonClass={isFormValid() ? 'button-valid': 'button-invalid'}
                    >
                        ENVIAR
                    </Button>
                </form>
                </Fade>
            {
                axiosResponse.type === 'error'
                ? <FormWrongSubmitModal isOpen={isModalOpen} close={closeModal} />
                : <FormSubmitModal isOpen={isModalOpen} close={closeModal} />
            }
            
        </React.Fragment>
    )
}

BraceletColumn.propTypes = {
    isBraceletForm: PropTypes.bool,
}

export default TicketForm