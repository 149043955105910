import React from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'

// import { navigate } from 'gatsby'

// import Button from '../components/button'

const HostingRoom = ({
  images,
  header,
  subheader,
  paragraph,
  left,
  id,
  roomId,
  link,
  size,
}) => {
  // const goToRoomDetail = () => {
  //   navigate(link)
  // }

  return (
    <div className="rooms__row" id={id}>
      <Fade duration={2000}>
        <img
          src={images[0]}
          srcSet={`${images[1]} 2x,
                        ${images[2]} 3x`}
          className={`rooms__row-image ${left && 'rooms__row-image--left'}`}
          alt="cover"
        />
      </Fade>
      <div
        className={`rooms__row-wrapper ${left && 'rooms__row-wrapper--left'}`}
      >
        <h1 className={`rooms__row-title ${size}`}>{header}</h1>
        <h3 className="rooms__row-subtitle">{subheader}</h3>
        <p className="rooms__row-text">{paragraph}</p>
        {/* <Button onClick={goToRoomDetail}>VER MÁS</Button> */}
      </div>
    </div>
  )
}

HostingRoom.propTypes = {
  images: PropTypes.array.isRequired,
  header: PropTypes.string.isRequired,
  subheader: PropTypes.string.isRequired,
  paragraph: PropTypes.string.isRequired,
  left: PropTypes.bool,
  id: PropTypes.string,
  roomId: PropTypes.string,
}

export default HostingRoom
