import React from 'react'
import PropTypes from "prop-types"

const RadioInput = ({label, onChange, checked, name, value, className, type = "radio"}) => (
    <label
        className={`radio-input ${className || ''}`}
    >
        {label}
        <input
            type={type}
            name={name}
            checked={checked}
            value={value}
            onChange={({target}) => onChange(target.checked)}
        />
        <span className="radio-input-check" />
    </label>
)

RadioInput.propTypes = {
    label: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    className: PropTypes.string,
    onChange: PropTypes.func.isRequired,
}

export default RadioInput